import loadable from "@loadable/component"
import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import FaviconAux from "../images/favicon.png"
import { connect } from "react-redux"

const Header = loadable(() => import("./header"))
const Footer = loadable(() => import("./footer"))
const Modules = loadable(() => import("./modules"))
const SocialModal = loadable(() => import("./socialModal"))

import { useEffect } from "react"

import {
  CHANGE_APIKEY_ACTION,
  CHANGE_DATA_TEMPLATE_ACTION,
  CHANGE_MAPKEY_ACTION,
} from "../redux/settingsDucks"

const isBrowser = typeof window !== "undefined"

const Layout = ({
  dispatch,
  children,
  preloader = false,
  property = {},
  development = {},
  novelty = {},
}) => {
  const data = useStaticQuery(graphql`
    query LayoutInfoQuery {
      realEstate {
        favicon
        env {
          APISERVER
          TENANTID
          CLIENTID
        }
        seo {
          title
          siteUrl
        }
        logo
        colors {
          primaryColor
          secondaryColor
          tertiaryColor
        }
        keys {
          tokko
          google_map
        }
        design {
          font_size
          global {
            file
            upperCase
          }
          h1 {
            file
            upperCase
          }
          h2 {
            file
            upperCase
          }
          h3 {
            file
            upperCase
          }
          h4 {
            file
            upperCase
          }
          h5 {
            file
            upperCase
          }
          p {
            file
            upperCase
          }
          icons
          border1
          border2
          border3
          border4
        }
      }
    }
  `)

  const {
    realEstate: {
      design: { border1, border2, border3, border4 },
      favicon,
    },
  } = data

  useEffect(() => {
    dispatch(CHANGE_DATA_TEMPLATE_ACTION(data?.realEstate?.env))
    dispatch(CHANGE_APIKEY_ACTION(data?.realEstate?.keys?.tokko))
    dispatch(CHANGE_MAPKEY_ACTION(data?.realEstate?.keys?.google_map))
  }, [data.realEstate])

  const body = isBrowser ? document.body : null
  const loader = isBrowser ? document.getElementById("preloader") : null

  useEffect(() => {
    setTimeout(
      () => {
        loader?.classList.add("preloader-hide")
        setTimeout(
          () => {
            body.classList.remove("preloader_active")
            body.classList.add("preloader_ready")
          },
          preloader ? 450 : 0
        )
      },
      preloader ? 450 : 0
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={favicon || FaviconAux} />
        <link rel="canonical" href={data.realEstate.seo.siteUrl} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <style type="text/css">{`
          @font-face {
            font-family: h1CustomFont;
            src: url(${data.realEstate.design.h1.file});
          }
          @font-face {
            font-family: h2CustomFont;
            src: url(${data.realEstate.design.h2.file});
          }
          @font-face {
            font-family: h3CustomFont;
            src: url(${data.realEstate.design.h3.file});
          }
          @font-face {
            font-family: h4CustomFont;
            src: url(${data.realEstate.design.h4.file});
          }
          @font-face {
            font-family: h5CustomFont;
            src: url(${data.realEstate.design.h5.file});
          }
          @font-face {
            font-family: pCustomFont;
            src: url(${data.realEstate.design.p.file});
          }
          @font-face {
            font-family: globalCustomFont;
            src: url(${data.realEstate.design.global.file});
          }
      `}</style>
        <style>
          {`\
          :root{\
            --primaryColor: ${data.realEstate.colors.primaryColor};\
            --secondaryColor: ${data.realEstate.colors.secondaryColor};\
            --tertiaryColor: ${data.realEstate.colors.tertiaryColor};\
            --globalfontSize: var(--fontSize${
              data.realEstate.design.font_size || "M"
            });\
            --customBorders: ${border1}px ${border2}px ${border3}px ${border4}px;\
            --border1: ${data.realEstate.design.border1}px;\
            --border2: ${data.realEstate.design.border2}px;\
            --border3: ${data.realEstate.design.border3}px;\
            --border4: ${data.realEstate.design.border4}px;\
          }\
        `}
        </style>
      </Helmet>
      <div className="global-wrapper">
        <Modules property={property} development={development} />
        <Header
          siteTitle={data.realEstate.seo?.title || `Title`}
          logo={data.realEstate.logo}
        />
        <div className="article">
          {
            <SocialModal
              development={development}
              property={property}
              novelty={novelty}
            />
          }
        </div>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default connect(state => ({}), null)(Layout)
